import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default form submission behavior
      if (searchText.trim()) {
        // Navigate to the search page with the search text as a query parameter
        navigate(`/search?searchText=${searchText.trim()}`);
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center p-4 shadow bg-white">
        {/* Search bar */}
        <div className="flex items-center border border-gray-300 rounded-md overflow-hidden md:ml-10">
          <FaSearch className="text-gray-400 ml-2" />
          <input
            type="text"
            placeholder="Search"
            className="py-2 px-4 outline-none w-64"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>

        {/* Phone number with icon */}
        <div className="flex items-center space-x-4 pl-2">
          {/* User avatar */}
          <Link to={"my-account"}>
            <div className="border border-blue-400 rounded-md p-1 bg-blue-100 w-10 h-10 flex items-center justify-center">
              {" "}
              {/* Adjusted size */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-blue-500" // Adjusted height and width
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-4.41 0-8 3.59-8 8v1h16v-1c0-4.41-3.59-8-8-8z"
                />
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
