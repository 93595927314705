export const TEXT_CONSTANTS = {
  // Titles
  REGISTER: "Register",
  PERSONAL_DETAILS: "Personal Details",
  COMPANY_DETAILS: "Company Details",
  LICENSE_BUSINESS_DETAILS: "Licence & Business details",
  PRODUCT_TITLE: "SSPLERP",
  COMPLETE_STEPS: "Complete this easy three steps to continue",
  COMPANY: "sadhrasya softTech",

  // Labels
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  EMAIL_ADDRESS: "Email address",
  MOBILE_NUMBER: "Mobile number",
  AADHAR_NUMBER: "Aadhar number",
  COMPANY_NAME: "Company name",
  ADDRESS: "Address",
  CITY: "City",
  PINCODE: "Pincode",
  STATE: "State",
  COUNTRY: "Country",
  GST_NUMBER: "GST number",
  DRUG_LICENSE_NUMBER: "Drug licence number",
  BUSINESS_TYPE: "Business type",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm password",
  PHONE_NUMBER: "Phone number",

  // Placeholders
  FIRST_NAME_PLACEHOLDER: "Your first name",
  LAST_NAME_PLACEHOLDER: "Your last name",
  EMAIL_PLACEHOLDER: "example@gmail.com",
  MOBILE_PLACEHOLDER: "+91 00000 00000",
  AADHAR_PLACEHOLDER: "1111 2222 3333",
  COMPANY_NAME_PLACEHOLDER: "ABC Pvt. Ltd.",
  ADDRESS_PLACEHOLDER: "1st street..",
  CITY_PLACEHOLDER: "NewYork",
  PINCODE_PLACEHOLDER: "000000",
  STATE_PLACEHOLDER: "NewYork",
  COUNTRY_PLACEHOLDER: "USA",
  GST_NUMBER_PLACEHOLDER: "ABC123456576",
  DRUG_LICENSE_NUMBER_PLACEHOLDER: "AD23454",
  PHONE_NUMBER_PLACEHOLDER: "+91 00000 00000",
  PASSWORD_PLACEHOLDER:
    "Min 8 character. Includes a special & capital character",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm password",

  // Button Text
  NEXT_BUTTON: "Next",
  SIGN_UP_BUTTON: "Sign up",

  // Sign-in Text
  ALREADY_HAVE_ACCOUNT: "Already have an account?",
  SIGN_IN: "Sign in",

  // Side Information
  SIDE_HEADING: "Manage all your orders and products at ",
  SIDE_DESCRIPTION: "Complete this easy three steps to continue",

  // Additional Information
  TERMS_AND_POLICY: "By clicking sign up, You agree to terms & policy",

  // Address Management
  SAVED_ADDRESS_HEADER: "Saved address",
  HOME_ADDRESS_PATH_PART1: "Home",
  HOME_ADDRESS_PATH_PART2: "/Address",
  ADD_NEW_ADDRESS: "Add New Address",
  NO_SAVED_ADDRESSES: "No saved addresses here!",
  NO_ADDRESSES_MESSAGE:
    "You can tap here to add a new address to the saved list.",
  ADDRESS_UPDATED_SUCCESS: "Address updated successfully",
  ADDRESS_ADDED_SUCCESS: "Address added successfully",
  ADDRESS_DELETED_SUCCESS: "Address deleted successfully",
  ERROR_SUBMITTING_ADDRESS: "Error submitting address",
  ERROR_FETCHING_ADDRESSES: "Error fetching addresses",
  DELETE_CONFIRMATION_TITLE: "Are you sure you want to delete this address?",
  DELETE_CONFIRMATION_MESSAGE:
    "After confirmation this address will not be recovered. You need to add it from fresh.",
  CANCEL: "Cancel",
  DELETE_ADDRESS: "Delete address",
  EDIT_ADDRESS: "Edit Address",
  ADD_NEW_ADDRESS_MODAL: "Add New Address",
  SAVE_ADDRESS: "Save Address",
  SAVING_ADDRESS: "Saving Address...",

  // My account
  MY_ACCOUNT: "My account",
  HOME_MY_ACCOUNT_PATH_PART1: "Home",
  HOME_MY_ACCOUNT_PATH_PART2: "/My account",
  MY_ACCOUNT_PERSONAL_DETAILS: "Personal details",
  MY_ACCOUNT_PERSONAL_DETAILS_LABEL:
    "Edit or change your personal details from below",
  MY_ACCOUNT_BUSINESS_DETAILS: "Business details",
  MY_ACCOUNT_BUSINESS_DETAILS_LABEL:
    "Edit or change your business details from below",
  MY_ACCOUNT_PROFILE_UPDATED_SUCCESSFULLY: "Profile Updated Successfully",
};

export const REJEX_CONSTANTS = {
  EMAIL_PATTERN: /^(?!.*\.\.)([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
  PASSWORD_PATTERN: "^((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,})$",
  ZIP_CODE_PATTERN: "^[0-9]{5}$",
  NAME_PATTERN: "^[A-Za-z]{2,}( [a-zA-Z]+)?(-[a-zA-Z]+)?$",
};

export const PAGINATION_CONSTANTS = {
  PER_PAGE: 10,
  PAGE: 1,
};
