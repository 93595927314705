// src/redux/slices/breadcrumbSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BreadcrumbState {
  breadcrumb: { name: string; category_id: string | null }[];
}

const initialState: BreadcrumbState = {
  breadcrumb: [{ name: "Products", category_id: "root" }],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    // Action to reset or initialize the breadcrumb
    resetBreadcrumb: (state) => {
      state.breadcrumb = [{ name: "Products", category_id: null }];
    },
    // Action to add a new category to the breadcrumb
    addBreadcrumb: (state, action: PayloadAction<{ name: string; category_id: string }>) => {
      state.breadcrumb.push(action.payload);
    },
    // Action to truncate breadcrumb to a certain level
    updateBreadcrumb: (state, action: PayloadAction<number>) => {
      state.breadcrumb = state.breadcrumb.slice(0, action.payload + 1);
    },
  },
});

export const { resetBreadcrumb, addBreadcrumb, updateBreadcrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
