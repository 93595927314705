import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ApprovalStatus() {
  const navigate = useNavigate();
  const [isUserApproved, setIsUserApproved] = useState<boolean | null>(null);

  const stepsApproved = [
    { label: "Registration form", status: "Done" },
    { label: "Admin approval", status: "Done" },
    { label: "Account created", status: "Done" },
  ];

  const stepsPending = [
    { label: "Registration form", status: "Done" },
    { label: "Admin approval", status: "Pending" },
    { label: "Account created", status: "Pending" },
  ];

  useEffect(() => {
    // Retrieve the approval status from local storage on component mount
    const approvalStatus = localStorage.getItem("isUserApproved");
    setIsUserApproved(approvalStatus === "true"); // Convert string to boolean
  }, []);

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen gradient justify-center items-center">
      <div className="w-full p-12 flex flex-col justify-center items-center">
        <div>
          <h1 className="text-2xl font-bold mb-8 text-white">SSPLERP</h1>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8 text-center w-1/2">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-xl font-bold text-blue-color mb-4">Approval status</h2>
          </div>
          <div className="flex items-center justify-between pb-6">
            {(isUserApproved ? stepsApproved : stepsPending).map((step, index) => (
              <div key={index} className="flex flex-col items-center">
                <div
                  className={`flex items-center justify-center w-10 h-10 rounded-full ${
                    step.status === "Done"
                      ? "button-color text-white"
                      : "bg-gray-100 border border-gray-300 text-gray-500"
                  }`}
                >
                  {step.status === "Done" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  ) : (
                    <div className="w-2 h-2 bg-transparent" />
                  )}
                </div>
                <div className="mt-2 text-center">
                  <span className="text-black font-bold">{step.label}</span>
                  <span
                    className={`block ${step.status === "Done" ? "text-green-500" : "text-gray-400"}`}
                  >
                    {step.status}
                  </span>
                </div>
                {index < stepsPending.length - 1 && (
                  <div className="flex-1 h-1 bg-gray-200" />
                )}
              </div>
            ))}
          </div>
          <button
            className="text-white rounded p-3 w-full font-semibold button-color"
            onClick={navigateToHome}
          >
            Explore Products
          </button>
          <p className="text-gray-600 mt-3">
            Meanwhile, you can still explore products and brands!
          </p>
        </div>
      </div>
    </div>
  );
}
