import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useSearchParams, useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import helperService from "../../services/helperService";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

const SearchedProducts = () => {
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE);
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of products per page
  const navigate = useNavigate(); // Initialize useNavigate
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);

  // Fetch searched products when searchText changes
  useEffect(() => {
    setProducts([]); // Clear previous results when searchText changes
    setPage(1); // Reset to page 1 when searchText changes
    fetchSearchedProducts(true); // Initial fetch
  }, [searchText]);

   // Fetch searched products from the API
   // Searched products are final level of categories whith will have final prodects of diffent sizes inside them
   const fetchSearchedProducts = async (loader: boolean = false) => {
    loader ? setLoading(true) : setLoading(false);
    try {
      const res: any = await apiService.getSearchedProducts(searchText || "", page, per_page);
      const products = res.data.categories;
      // If fewer products are returned than per_page, it means there's no more data
      if (products.length < per_page) setEndOfScrollData(true);

      // Append new products to the list
      setProducts((prevProducts) => [...prevProducts, ...products]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const navigateToProductPage = (product:any) => {
    navigate(`/product/${product.name.replace(/\s+/g, '-')}/${product.category_id}`);
  }

  useEffect(() => {
    if (scrolled) {
      const loader = false;
      // Fetch categories only if scrolled
      fetchSearchedProducts(loader); // Will use the updated 'page' from state
    }
  }, [page]);

  const fetchMoreData = async () => {
    setScrolled(true);
    loadMoreProducts(); // Increment page, and useEffect will handle fetching the data
  };

  const loadMoreProducts = () => {
    setPage((prevPage) => prevPage + 1); // Increment the page
  };

  return (
    <div className="w-full mx-auto p-4">
       {/* Loader while fetching data */}
       {loading ? (
        <div className="flex justify-center items-center pt-64" data-testid="clip-loader">
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
      <>
      <h1 className="text-2xl font-semibold">{searchText}</h1>
      <p className="text-gray-500 mt-1">{products.length} Total Products</p>
      <InfiniteScroll
       dataLength={products.length}
       next={fetchMoreData}
       hasMore={!endOfScrollData}
       loader={
         <div className="flex justify-center items-center my-4"  data-testid="clip-loader">
           <ClipLoader color="#0000ff" loading={true} size={30} />
         </div>
       }
       height={800}
       scrollThreshold={0.9}
       endMessage={
         <div className="flex justify-center items-center my-4">
           <p className="text-gray-600 font-medium text-center">
           {products.length > 0 ? "Yay! You have seen it all." : "No data available"}
           </p>
         </div>
       }
       className="border-t border-gray-200 mt-4">
        {products.map((product, index) => (
          <div
            key={index}
            className="flex justify-between items-center border-b border-gray-200 py-4"
            onClick = {() => navigateToProductPage(product)}
          >
            <span className="font-semibold">{product.name}</span>
            <FaChevronRight className="text-gray-400" />
          </div>
        ))}
      </InfiniteScroll>
      </>
         )}
    </div>
  );
};

export default SearchedProducts;
