const helperService = {
 
  removeEmptyKeys: (obj: any): any => {
    // If the input is not an object, return it as is (base case for recursion)
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    // Iterate over the keys of the object
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      // If the value is an empty string, delete the key
      if (value === "") {
        delete obj[key];
      } 
      // If the value is an object, recursively clean the nested object
      else if (typeof value === 'object' && value !== null) {
        helperService.removeEmptyKeys(value);

        // After cleaning the nested object, check if it's empty and remove it
        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      }
    });

    return obj;
  },
 
  

  extractSuccessMessage: (response: any): string => {
    return response.message || response.data?.message || "Operation successful.";
  },

  extractErrorMessage: (error: any): string => {
    return (
      error.response?.data?.message ||
      error.message || "Operation failed."
    );
  },
};

export default helperService;
