import React from "react";
import { TEXT_CONSTANTS } from "../../shared/constants/globalConstants";

const Footer: React.FC = () => {
  return (
    <div className="light-blue-bg text-center p-4">
      <div className="flex justify-between items-center">
        <p className="text-sm text-gray-600">
          Made with <span className="text-red-500">❤️</span> by
          <span className="text-blue-500 font-bold ml-1">
            {TEXT_CONSTANTS.COMPANY}
          </span>
        </p>
        <p className="text-sm text-gray-600">
          All rights reserved to
          <a
            href="https://sadhrasya.com"
            className="text-blue-500 font-bold ml-1"
          >
            {TEXT_CONSTANTS.COMPANY}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;