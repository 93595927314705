import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Register from "./pages/auth/register/Register";
import SignIn from "./pages/auth/sign-in/SignIn";
import ProtectedRoute from "./shared/components/protected-route/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import ApprovalStatus from "./pages/approval-status/ApprovalStatus";
import SearchedProducts from "./pages/searched-products/SearchedProducts";
import Product from "./pages/product/Product";
import Category from "./pages/category/Category";
import Address from "./pages/address/Address";
import MyAccount from "./pages/my-account/MyAccount";
import Layout from "./shared/components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  // Centralized routing configuration
  const commonRoutes = [
    { path: "/sign-in", element: <SignIn /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/approval-status", element: <ApprovalStatus /> },
    { path: "*", element: <h1>Page not found</h1> },
  ];

  const protectedRoutes = [
    { path: "/", element: <Category />, index: true },
    { path: "address", element: <Address /> },
    { path: "my-account", element: <MyAccount /> },
    { path: "*", element: <h1>Page not found</h1> },
  ];

  const publicRoutes = [
    { path: "search", element: <SearchedProducts /> },
    { path: "product", element: <Product /> },
    { path: "category", element: <Category /> },
    { path: "category/:categoryName", element: <Category /> },
    { path: "category/:categoryName/:categoryId", element: <Category /> },
    { path: "product/:productName/:productId", element: <Product /> },
  ];

  return (
    <>
      <Routes>
        {commonRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {/* Common layout (Header sidebar and footer) */}
        <Route path="/" element={<Layout />}>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {/* Protected routes */}
          <Route path="/" element={<ProtectedRoute />}>
            {protectedRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                index={route.index}
              />
            ))}
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000} // 3 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
