import { Outlet } from "react-router-dom";
import Header from "../../../pages/header/Header";
import Sidebar from "../../../pages/sidebar/Sidebar";
import Footer from "../../../pages/footer/Footer";

const Layout = () => {
  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <Sidebar />

      <div className="flex flex-1 flex-col">
        {/* Header */}
        <Header />

        {/* Main Content */}
        <div className="flex-grow p-4">
          <Outlet />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
