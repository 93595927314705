import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
  en: {
    translation: {
      welcome: "Welcome to React",
      // Add more translations here
    },
  },
  fr: {
    translation: {
      welcome: "Bienvenue à React",
      // Add more translations here
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language if translation is missing
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
