import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TEXT_CONSTANTS } from "../../shared/constants/globalConstants";
import { ReactComponent as MapIcon } from "../../assets/images/map.svg";
import { ReactComponent as ShoppingBagIcon } from "../../assets/images/shopping-bag.svg";
import { ReactComponent as ShoppingCartIcon } from "../../assets/images/shopping-cart.svg";
import { ReactComponent as SidebarLeftIcon } from "../../assets/images/sidebar-left.svg";
import { ReactComponent as MessageTextIcon } from "../../assets/images/message-text.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { ReactComponent as ProductIcon } from "../../assets/images/products.svg";
import { useDispatch } from "react-redux";
import { resetBreadcrumb } from "../../redux/slices/breadcrumSlice";

const menuItems = [
  {
    label: "Products",
    path: "/category/root",
    icon: <ProductIcon width={20} height={20} />,
  },
  {
    label: "Cart",
    path: "/cart",
    icon: <ShoppingBagIcon width={20} height={20} />,
  },
  {
    label: "Orders",
    path: "/orders",
    icon: <ShoppingCartIcon width={20} height={20} />,
  },
  {
    label: "Saved Address",
    path: "/address",
    icon: <MapIcon width={20} height={20} />,
  },
];

const menuItemsSecondary = [
  {
    label: "Contact Us",
    path: "/contact",
    icon: <MessageTextIcon width={20} height={20} />,
  },
  {
    label: "Logout",
    path: "/logout",
    icon: <LogoutIcon width={20} height={20} />,
  },
];

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string>("Products");
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (item: { label: string; path: string }) => {
    setSelectedItem(item.label);
    // Check if the path is "/category/root"
    //temporary handle
    handleRootCategoryNav(item.path);
    navigate(item.path);
  };

  const handleRootCategoryNav = (path: string) => {
    if (path === "/category/root") {
      // Reset breadcrumb if the path matches
      dispatch(resetBreadcrumb());
    }
  };

  // Set selected item based on the current URL
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem =
      menuItems.find((item) => item.path === currentPath) ||
      menuItemsSecondary.find((item) => item.path === currentPath);

    if (currentItem) {
      setSelectedItem(currentItem.label);
    }
  }, [location]);

  return (
    <div
      className={`min-h-screen bg-white shadow-md ${
        isOpen ? "w-64" : "sidebar-close"
      } transition-width duration-300 flex flex-col`}
    >
      <div className="flex items-center justify-between p-4">
        {isOpen && (
          <h1 className="lg:text-2xl md:text-2xl sm:text-l text-dark-blue font-bold transition-opacity duration-300">
            {TEXT_CONSTANTS.PRODUCT_TITLE}
          </h1>
        )}
        <SidebarLeftIcon width={20} height={20} onClick={toggleSidebar} />
      </div>
      {isOpen ? <hr className="mt-3" /> : <hr className="mt-6" />}

      <div className="mt-6 flex-grow">
        <ul>
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.label}
              className={`flex items-center px-4 py-2 text-gray-600 ${
                selectedItem === menuItem.label
                  ? "bg-blue-100"
                  : "hover:bg-gray-100"
              } rounded-lg cursor-pointer ${isOpen ? "mb-2 mx-2" : ""}`}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              {menuItem.icon}
              <span
                className={`ml-4 transition-all duration-300 font-semibold ${
                  !isOpen && "opacity-0 hidden"
                } ${selectedItem === menuItem.label ? "text-blue-500" : ""}`}
              >
                {menuItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-auto mb-4">
        <hr className="my-4" />
        <ul>
          {menuItemsSecondary.map((menuItem) => (
            <li
              key={menuItem.label}
              className={`flex items-center px-4 py-2 text-gray-600 ${
                selectedItem === menuItem.label
                  ? "bg-blue-100"
                  : "hover:bg-gray-100"
              } rounded-lg cursor-pointer ${isOpen ? "mb-2 mx-2" : ""}`}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              {menuItem.icon}
              <span
                className={`ml-4 transition-all duration-300 font-semibold ${
                  !isOpen && "opacity-0 hidden"
                } ${selectedItem === menuItem.label ? "text-blue-500" : ""}`}
              >
                {menuItem.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
