import React from "react";
import { TEXT_CONSTANTS } from "../../constants/globalConstants";
import { useNavigate } from "react-router-dom";
export default function RegisteredSuccess() {
    const navigate = useNavigate();

    const navigateToSignIn = () => {
        navigate("/sign-in");
    }

  return (
    <>
      <div className="flex flex-col lg:flex-row min-h-screen gradient justify-center items-center">
        <div className="w-full p-12 flex flex-col justify-center items-center">
          <div>
            <h1 className="text-2xl font-bold mb-8 text-white">
              {TEXT_CONSTANTS.PRODUCT_TITLE}
            </h1>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 text-center max-w">
            <div className="flex justify-center items-center mb-4">
              <div
                className="rounded-full p-4"
                style={{
                  background:
                    "radial-gradient(circle, rgba(173,216,230,1) 20%, rgba(255,255,255,1) 80%)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-12 h-12 text-blue-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <h2 className="text-3xl font-bold text-blue-color mb-4">
              Registered successfully
            </h2>
            <p className="text-gray-600 mb-6">
              Please log in with your email & password
            </p>

            <button className="text-white rounded p-3 w-full font-semibold button-color" onClick={navigateToSignIn}>
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
