import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TEXT_CONSTANTS } from "../../shared/constants/globalConstants";
import { myProfileSchema } from "../../shared/constants/validationSchemas";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";

function MyAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(myProfileSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      aadhar: "",
      companyName: "",
      gstNumber: "",
      drugLicence: "",
      phoneNumber: "",
    },
  });

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      try {
        const { data } = await apiService.getUserProfile();
        setValue("firstName", data.user.first_name || "Hardik");
        setValue("lastName", data.user.last_name || "");
        setValue("email", data.user.email || "");
        setValue("mobile", data.user.mobile_number || "");
        setValue("aadhar", data.user.aadhar || "123456789012");
        setValue("companyName", data.company.company_name || "");
        setValue("gstNumber", data.company.gst_number || "");
        setValue("drugLicence", data.company.drug_licence_number || "");
        setValue("phoneNumber", data.company.phone_number || "");
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [setValue]);

  const handleSubmitForm = async (data: any) => {
    const payload = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile_number: data.mobile,
        aadhar_number: data.aadhar,
      },
      company: {
        company_name: data.companyName,
        gst_number: data.gstNumber,
        drug_licence_number: data.drugLicence,
        phone_number: data.phoneNumber,
      },
    };

    try {
      setIsSubmitting(true);
      await apiService.updateUserProfile(payload);
      setIsEdited(false);
      toast.success(TEXT_CONSTANTS.MY_ACCOUNT_PROFILE_UPDATED_SUCCESSFULLY);
    } catch (error: any) {
      toast.error(error);
      console.error("Error updating profile:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = () => {
    setIsEdited(true);
  };

  return (
    <section className="p-4 h-full">
      <div className="flex item-center flex-col mb-4 border-b pb-4 w-full">
        <h1 className="text-2xl font-bold">{TEXT_CONSTANTS.MY_ACCOUNT}</h1>
        <p className="font-semibold text-[#44A0F1]">
          {TEXT_CONSTANTS.HOME_MY_ACCOUNT_PATH_PART1}
          <span className="text-black">
            {TEXT_CONSTANTS.HOME_MY_ACCOUNT_PATH_PART2}
          </span>
        </p>
      </div>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {/* Personal Details */}
        <h2 className="font-bold text-xl">
          {TEXT_CONSTANTS.MY_ACCOUNT_PERSONAL_DETAILS}
        </h2>
        <p className="text-sm pt-1">
          {TEXT_CONSTANTS.MY_ACCOUNT_PERSONAL_DETAILS_LABEL}
        </p>

        <section className="my-4">
          <div className="flex w-full gap-4 lg:flex-row flex-col">
            <div className="w-full lg:pb-4">
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                type="text"
                placeholder="First Name"
                {...register("firstName")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.firstName?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.lastName?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                {...register("email")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.email?.message}</p>
            </div>
          </div>

          <div className="flex w-full gap-4 lg:flex-row flex-col lg:pt-0 pt-4">
            <div className="w-full">
              <label htmlFor="mobile">Mobile Number</label>
              <input
                id="mobile"
                type="text"
                placeholder="Mobile Number"
                {...register("mobile")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.mobile?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="aadhar">Aadhar Number</label>
              <input
                id="aadhar"
                type="text"
                placeholder="Aadhar Number"
                {...register("aadhar")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading || !!getValues("aadhar")}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.aadhar?.message}</p>
            </div>
          </div>
        </section>

        <div className="border-b w-full"></div>
        {/* Business Details */}
        <h2 className="font-bold text-xl mt-2">
          {TEXT_CONSTANTS.MY_ACCOUNT_BUSINESS_DETAILS}
        </h2>
        <p className="text-sm pt-1">
          {TEXT_CONSTANTS.MY_ACCOUNT_BUSINESS_DETAILS_LABEL}
        </p>

        <section className="my-4 flex flex-col gap-4">
          <div className="flex w-full gap-4 lg:flex-row flex-col">
            <div className="w-full">
              <label htmlFor="companyName">Company Name</label>
              <input
                id="companyName"
                type="text"
                placeholder="Company Name"
                {...register("companyName")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.companyName?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="gstNumber">GST Number</label>
              <input
                id="gstNumber"
                type="text"
                placeholder="GST Number"
                {...register("gstNumber")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading || !!getValues("gstNumber")}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.gstNumber?.message}</p>
            </div>
          </div>

          <div className="flex w-full gap-4 lg:flex-row flex-col">
            <div className="w-full">
              <label htmlFor="drugLicence">Drug Licence Number</label>
              <input
                id="drugLicence"
                type="text"
                placeholder="Drug Licence Number"
                {...register("drugLicence")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading || !!getValues("drugLicence")}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.drugLicence?.message}</p>
            </div>

            <div className="w-full">
              <label htmlFor="phoneNumber">Company Phone Number</label>
              <input
                id="phoneNumber"
                type="text"
                placeholder="Phone Number"
                {...register("phoneNumber")}
                className="w-full p-3 border rounded-lg"
                disabled={isLoading}
                onChange={handleInputChange}
              />
              <p className="text-red-600">{errors.phoneNumber?.message}</p>
            </div>
          </div>
        </section>

        <div className="border-b w-full"></div>

        <section className="flex justify-between item-center mt-2">
          <div>
            <h2 className="font-bold text-xl">Change Password</h2>
            <p className="text-sm pt-1">
              Enter current and new password to change password
            </p>
          </div>
          <button className="bg-gray-50 px-8 rounded-md border text-sm font-semibold">
            Change Password
          </button>
        </section>

        <div className="w-full flex item-center justify-center">
          <button
            type="submit"
            className={`text-white px-20 py-4 rounded-lg mt-4 ${
              isEdited ? "teal-button" : "bg-gray-300 cursor-not-allowed"
            }`}
            disabled={isSubmitting || !isEdited}
          >
            {isSubmitting ? "Saving Changes..." : "Save Changes"}
          </button>
        </div>
      </form>
    </section>
  );
}

export default MyAccount;
