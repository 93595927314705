import { useEffect, useState } from "react";
import { ReactComponent as MapIcon } from "../../assets/images/map.svg";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import { FaX } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as TrashIcon } from "../../assets/images/trash.svg";
import { ReactComponent as TrashOutlineIcon } from "../../assets/images/trash-outline.svg";
import {
  AddAddressSchema,
  EditAddressSchema,
} from "../../shared/constants/validationSchemas";
import { TEXT_CONSTANTS } from "../../shared/constants/globalConstants.ts";
import { CiEdit } from "react-icons/ci";

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      currentAddress ? EditAddressSchema : AddAddressSchema,
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      address_line1: "",
      address_line2: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
      default_billing: false,
      default_shipping: false,
    },
  });

  const handleAddAddress = async (data) => {
    setIsLoading(true);
    try {
      if (currentAddress) {
        const {
          address_line1,
          address_line2,
          city,
          pincode,
          state,
          country,
          default_billing,
          default_shipping,
          name,
        } = data;

        const payload = {
          name,
          address_line1,
          city,
          pincode,
          state,
          country,
          default_billing,
          default_shipping,
        };

        if (address_line2) {
          payload.address_line2 = address_line2;
        } else payload.address_line2 = undefined;

        const response = await apiService.editUserAddress(
          currentAddress.address_id,
          payload,
        );
        setAddresses(
          addresses.map((address) =>
            address.address_id === currentAddress.address_id
              ? response.data
              : address,
          ),
        );
        toast.success(TEXT_CONSTANTS.ADDRESS_UPDATED_SUCCESS);
      } else {
        const {
          address_line1,
          address_line2,
          city,
          pincode,
          state,
          country,
          name,
        } = data;
        const payload = {
          name,
          address_line1,
          city,
          pincode,
          state,
          country,
          default_billing: false,
          default_shipping: false,
        };

        if (address_line2) {
          payload.address_line2 = address_line2;
        } else payload.address_line2 = undefined;

        const response = await apiService.addUserAddress({
          ...payload,
        });

        setAddresses([...addresses, response.data]);
        toast.success(TEXT_CONSTANTS.ADDRESS_ADDED_SUCCESS);
      }
      setShowModal(false);
      reset();
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          TEXT_CONSTANTS.ERROR_SUBMITTING_ADDRESS,
      );
      console.error("Error submitting address:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.getUserAddress();
      setAddresses(response.data.addresses);
    } catch (error) {
      toast.error(TEXT_CONSTANTS.ERROR_FETCHING_ADDRESSES);
      console.error("Error fetching addresses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handelUpdateDefaultShipping = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiService.editUserAddress(data.address_id, {
        default_shipping: !data.default_shipping,
      });
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      fetchAddresses();
    }
  };

  const handelUpdateDefaultBilling = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiService.editUserAddress(data.address_id, {
        default_billing: !data.default_billing,
      });
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      fetchAddresses();
    }
  };

  const handleDeleteAddress = async () => {
    setIsLoading(true);
    try {
      await apiService.deleteUserAddress(addressToDelete);
      setAddresses((prev) =>
        prev.filter((address) => address.address_id !== addressToDelete),
      );
      setShowDeleteDialog(false);
      toast.success(TEXT_CONSTANTS.ADDRESS_DELETED_SUCCESS);
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          TEXT_CONSTANTS.ERROR_FETCHING_ADDRESSES,
      );
      console.error("Error deleting address:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <section className="h-full p-4">
      <div className="mb-4 flex w-full items-center justify-between border-b pb-4">
        <div className="item-center flex flex-col">
          <h1 className="text-2xl font-bold">
            {TEXT_CONSTANTS.SAVED_ADDRESS_HEADER}
          </h1>
          <p className="font-semibold text-[#44A0F1]">
            {TEXT_CONSTANTS.HOME_ADDRESS_PATH_PART1}
            <span className="text-black">
              {TEXT_CONSTANTS.HOME_ADDRESS_PATH_PART2}
            </span>
          </p>
        </div>
        {addresses.length ? (
          <button
            onClick={() => {
              setCurrentAddress(null);
              setShowModal(true);
            }}
            className="flex items-center rounded-lg bg-[#2A9D8A] px-4 py-2 text-white"
          >
            <FaPlus className="mr-2" />
            {TEXT_CONSTANTS.ADD_NEW_ADDRESS}
          </button>
        ) : (
          ""
        )}
      </div>

      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <div
            class="inline-block size-10 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
            role="status"
            aria-label="loading"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {addresses.length ? (
            <div className="max-h-[calc(100vh-279.3px)] overflow-y-auto">
              {addresses.map((address, index) => (
                <div
                  key={index}
                  className="my-4 flex flex-col justify-between gap-2 rounded-lg border border-[#DBDBDB] p-4 px-8 xl:flex-row"
                >
                  <div>
                    <div>
                      <p>
                        <p className="font-semibold">
                          {address.name}

                          {(address.default_shipping ||
                            address.default_billing) && (
                            <span className="mx-2 font-semibold text-[#AB9715]">
                              |
                            </span>
                          )}

                          <span className="font-semibold text-[#AB9715]">
                            {address.default_shipping &&
                              "Default Shipping Address"}
                          </span>

                          {address.default_shipping &&
                            address.default_billing && (
                              <span className="mx-2 font-semibold text-[#AB9715]">
                                |
                              </span>
                            )}

                          <span className="font-semibold text-[#AB9715]">
                            {address.default_billing &&
                              "Default Billing Address"}
                          </span>
                        </p>

                        <p
                          title={`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                          className="max-w-[50rem] truncate font-semibold sm:max-w-[20rem] md:max-w-[40rem]"
                        >
                          {`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-3 sm:justify-between">
                    <div
                      class={`flex items-center space-x-2 rounded-lg border px-4 py-4 ${
                        address.default_billing
                          ? "bg-[#E6F8F5] text-[#2A9D8A]"
                          : "bg-gray-100"
                      }`}
                    >
                      <input
                        type="checkbox"
                        id="checkbox1"
                        class="form-checkbox h-5 w-5 cursor-pointer accent-[#2A9D8A]"
                        checked={address.default_billing}
                        onClick={() => handelUpdateDefaultBilling(address)}
                        disabled={isLoading}
                      />
                      <label
                        for="checkbox1"
                        class={`${
                          address.default_billing ? "text-[#2A9D8A]" : ""
                        } font-semibold`}
                      >
                        Default Billing
                      </label>
                    </div>

                    <div
                      class={`flex items-center space-x-2 rounded-lg border px-4 py-4 ${
                        address.default_shipping
                          ? "bg-[#E6F8F5] text-[#2A9D8A]"
                          : "bg-gray-100"
                      }`}
                    >
                      <input
                        type="checkbox"
                        id="checkbox2"
                        class="form-checkbox h-5 w-5 cursor-pointer accent-[#2A9D8A]"
                        checked={address.default_shipping}
                        onClick={() => handelUpdateDefaultShipping(address)}
                        disabled={isLoading}
                      />
                      <label
                        for="checkbox2"
                        class={`${
                          address.default_shipping ? "text-[#2A9D8A]" : ""
                        } font-semibold`}
                        checked={address.default_billing}
                      >
                        Default Shipping
                      </label>
                    </div>

                    <button
                      className="rounded-lg border bg-[#FDEAEA] p-2"
                      onClick={() => {
                        setAddressToDelete(address.address_id);
                        setShowDeleteDialog(true);
                      }}
                    >
                      <TrashOutlineIcon className="h-10 w-10" />
                    </button>

                    <button
                      className="rounded-lg border bg-[#DAECFC] p-2"
                      id={address.address_id}
                      onClick={() => {
                        setCurrentAddress(address);
                        setShowModal(true);
                        setValue("name", address.name);
                        setValue("address_line1", address.address_line1);
                        setValue("address_line2", address.address_line2);
                        setValue("city", address.city);
                        setValue("pincode", address.pincode);
                        setValue("state", address.state);
                        setValue("country", address.country);
                        setValue("default_billing", address.default_billing);
                        setValue("default_shipping", address.default_shipping);
                      }}
                    >
                      <CiEdit className="h-10 w-10 text-[#1079D4]" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-4 flex h-[40rem] flex-col items-center justify-center rounded-xl border bg-[#FAFAFA] p-4 text-center">
              <MapIcon className="h-20 w-20 stroke-current text-[#939393]" />
              <p className="my-2 mt-4 text-xl font-bold">
                {TEXT_CONSTANTS.NO_SAVED_ADDRESSES}
              </p>
              <p className="text-sm">{TEXT_CONSTANTS.NO_ADDRESSES_MESSAGE}</p>
              <button
                onClick={() => {
                  setCurrentAddress(null);
                  setShowModal(true);
                }}
                className="button-color mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
              >
                {TEXT_CONSTANTS.ADD_NEW_ADDRESS}
              </button>
            </div>
          )}
        </>
      )}

      {showModal && (
        <div className="fixed inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
          <div className="w-full max-w-xl rounded-lg bg-white p-6 shadow-lg">
            <div className="item-center align-center flex justify-between">
              <h2 className="mb-4 text-2xl font-semibold">
                {currentAddress
                  ? TEXT_CONSTANTS.EDIT_ADDRESS
                  : TEXT_CONSTANTS.ADD_NEW_ADDRESS_MODAL}
              </h2>
              <div className="cursor-pointer">
                <div className="rounded-full bg-gray-100 p-2">
                  <FaX
                    className=""
                    onClick={() => {
                      setShowModal(false);
                      reset();
                    }}
                  />
                </div>
              </div>
            </div>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(handleAddAddress)}
            >
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  {...register("name")}
                  className="w-full rounded-lg border p-2"
                />
                {errors.name?.message && (
                  <p className="m-0 p-0 text-red-600">{errors.name?.message}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="address_line1"
                  placeholder="Address Line 1"
                  {...register("address_line1")}
                  className="mt-0 w-full rounded-lg border p-2"
                />

                {errors.address_line1?.message && (
                  <p className="text-red-600">
                    {errors.address_line1?.message}
                  </p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="address_line2"
                  placeholder="Address Line 2"
                  {...register("address_line2")}
                  className="w-full rounded-lg border p-2"
                />

                {errors.address_line2?.message && (
                  <p className="text-red-600">
                    {errors.address_line2?.message}
                  </p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="city"
                  placeholder="City/Town"
                  {...register("city")}
                  className="w-full rounded-lg border p-2"
                />

                {errors.city?.message && (
                  <p className="text-red-600">{errors.city?.message}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  {...register("state")}
                  className="w-full rounded-lg border p-2"
                />

                {errors.state?.message && (
                  <p className="text-red-600">{errors.state?.message}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  {...register("country")}
                  className="w-full rounded-lg border p-2"
                />

                {errors.country?.message && (
                  <p className="text-red-600">{errors.country?.message}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="pincode"
                  placeholder="Pincode"
                  {...register("pincode")}
                  className="w-full rounded-lg border p-2"
                />

                {errors.pincode?.message && (
                  <p className="text-red-600">{errors.pincode?.message}</p>
                )}
              </div>

              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="button-color rounded-lg bg-blue-600 px-10 py-3 text-white"
                  disabled={isLoading}
                >
                  {isLoading
                    ? TEXT_CONSTANTS.SAVING_ADDRESS
                    : TEXT_CONSTANTS.SAVE_ADDRESS}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDeleteDialog && (
        <div className="fixed inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
          <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
            <div className="flex items-center justify-center rounded-t-lg p-4">
              <div className="rounded-full bg-[radial-gradient(circle,_rgba(255,0,0,0.3),_rgba(255,255,255,0))] p-6">
                <TrashIcon className="text-lg text-white" />
              </div>
            </div>
            <h3 className="my-3 text-center text-base font-bold">
              {TEXT_CONSTANTS.DELETE_CONFIRMATION_TITLE}
            </h3>
            <p className="text-center text-sm">
              {TEXT_CONSTANTS.DELETE_CONFIRMATION_MESSAGE}
            </p>
            <div className="mt-4 flex justify-between gap-2">
              <button
                className="flex-1 rounded-lg bg-gray-100 px-4 py-2 font-semibold text-gray-600"
                onClick={() => setShowDeleteDialog(false)}
              >
                {TEXT_CONSTANTS.CANCEL}
              </button>
              <button
                className="flex-1 rounded-lg bg-red-600 px-4 py-2 text-white"
                onClick={handleDeleteAddress}
                disabled={isLoading}
              >
                {TEXT_CONSTANTS.DELETE_ADDRESS}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Address;
