import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  addBreadcrumb,
  resetBreadcrumb,
} from "../../redux/slices/breadcrumSlice";
import { ReactComponent as DefaultProductImage } from "../../assets/images/default-product-image.svg";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";

const Category = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryTitle, setCategoryTitle] = useState<string>("All products");
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE); // Track page number
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of items per page
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const [skipEffect, setSkipEffect] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [rootCategoryNo, setRootCategoryNo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState<{
    name: string;
    category_id: string;
  } | null>(null);
  const breadcrumb = useSelector(
    (state: RootState) => state.breadcrumb.breadcrumb
  );
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    // Fetch categories whenever categoryId changes
    setPage(1);
    setEndOfScrollData(false);
    if (skipEffect) {
      setSkipEffect(false); // Reset flag
      return; // Skip the effect
    }
    setCategories([]);
    if (breadcrumb.length > 1) {
      const category = breadcrumb[breadcrumb.length - 1];
      const breadcrumAction = true;
      getSubCategory(category, breadcrumAction);
    } else {
      navigate("/category/root");
      fetchRootCategories(); // Fetch root categories if no categoryId
    }
  }, [breadcrumb]);

  useEffect(() => {
    // Cleanup function to run when component unmounts
    return () => {
      console.log("Component unmounted, running cleanup function");
      cleanupOnUnmount();
    };
  }, []);

  const cleanupOnUnmount = () => {
    // Add your cleanup logic here
    dispatch(resetBreadcrumb());
  };

  const fetchRootCategories = async (loader?: boolean) => {
    loader === false ? setLoading(false) : setLoading(true);
    try {
      const res: any = await apiService.getRootCategories(page, per_page);
      const { categories } = res.data;
      setRootCategoryNo(res.data.total);
      categories.length < per_page && setEndOfScrollData(true);
      setCategoryTitle("All products");
      setCategories((prev: any) => [...prev, ...categories]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getSubCategory = async (category: any, breadcrumbAction?: boolean) => {
    setLoading(true);
    try {
      setRootCategoryNo(null);
      const category_id: string = category.category_id;
      const res: any = await apiService.getSubCategories(category_id);
      const { categories } = res.data;
      if (categories.length === 0) {
        // setSkipEffect(true);
        // dispatch(addBreadcrumb({ name: category.name, category_id }));
        navigateToProductPage(category);
        return;
      }
      // Update breadcrumb and navigate to the subcategory
      if (breadcrumbAction) {
        setCategoryTitle(res.data.name);
      } else {
        setCategoryTitle(res.data.name);
        navigate(
          `/category/${res.data.name.replace(/\s+/g, "-")}/${res.data.category_id}`
        );
        setSelectedCategory({ name: category.name, category_id });
        setSkipEffect(true);
        // Update the categories in state
      }
      setCategories(categories);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
    }
    setLoading(false);
  };

  useEffect(() => {
    if (skipEffect && selectedCategory) {
      // Dispatch addBreadcrumb when skipEffect is true and we have selectedCategory
      dispatch(
        addBreadcrumb({
          name: selectedCategory.name,
          category_id: selectedCategory.category_id,
        })
      );

      // Optionally reset skipEffect and selectedCategory after the dispatch
      setSelectedCategory(null);
    }
  }, [skipEffect]);

  const navigateToProductPage = (category: any) => {
    navigate(
      `/product/${category.name.replace(/\s+/g, "-")}/${category.category_id}`
    );
  };

  // const addToBreadcrumb = (category:any) =>{
  //   const category_id = category.category_id
  //   dispatch(addBreadcrumb({ name: category.name, category_id }));
  // }

  useEffect(() => {
    if (scrolled) {
      // Fetch categories only if scrolled
      setScrolled(false);
      const loader = false;
      fetchRootCategories(loader); // Will use the updated 'page' from state
    }
  }, [page]); // Trigger effect when 'page' changes

  const fetchMoreData = async () => {
    setScrolled(true);
    loadMoreCategories(); // Increment page, and useEffect will handle fetching the data
  };

  const loadMoreCategories = () => {
    setPage((prevPage) => prevPage + 1); // Increment the page
  };

  return (
    <div className="mx-auto">
      {/* Loader covering the entire section */}
      {loading ? (
        <div className="flex justify-center items-center pt-64">
          {" "}
          {/* Center loader vertically */}
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div className="pl-4 mt-2">
            <h1 className="text-2xl font-bold mb-4">{categoryTitle}</h1>
            {/* Conditionally Render Breadcrumb */}
            {breadcrumb.length > 1 && <Breadcrumb />}
            {rootCategoryNo !== null && (
              <p className="text-gray-500 mt-1">
                {rootCategoryNo} Total Products
              </p>
            )}
          </div>
          <div className="p-4">
            <hr />
          </div>
          <InfiniteScroll
            dataLength={categories.length}
            next={fetchMoreData}
            hasMore={!endOfScrollData}
            loader={
              <div className="flex justify-center items-center h-64 my-4">
                <ClipLoader color="#0000ff" loading={true} size={30} />
              </div>
            }
            height={800}
            scrollThreshold={0.8}
            endMessage={
              <div className="flex justify-center items-center my-4 h-64">
                {" "}
                {/* Set a height for vertical centering */}
                <p className="text-gray-600 font-medium text-center">
                  {categories.length > 0
                    ? "Yay! You have seen it all."
                    : "No data available"}
                </p>
              </div>
            }
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
          >
            {categories.map((category: any) => (
              <div
                key={category.category_id}
                onClick={() => getSubCategory(category)}
              >
                <div className="border border-solid rounded-lg shadow hover:shadow-lg transition m-4">
                  {/* Conditionally render the actual image if media_link exists, otherwise show the DefaultProductImage */}
                  {category?.media?.media_link ? (
                    <img
                      src={category.media.media_link}
                      alt={category.name}
                      className="w-full h-80"
                    />
                  ) : (
                    <DefaultProductImage className="w-full h-80" />
                  )}
                </div>
                <p className="text-center font-semibold">{category.name}</p>
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default Category;
