import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
// import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
// import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";

const Product: React.FC = () => {
  const { productId } = useParams<{ productId?: string }>(); // Get productId from URL params
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE);
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of products per page
  // const breadcrumb = useSelector(
  //   (state: RootState) => state.breadcrumb.breadcrumb
  // );
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [productTitle, setProductTitle] = useState<string>("All products");
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []); // Re-run the effect when productId or page changes

  const fetchProducts = async (loader?: boolean) => {
    loader === false ? setLoading(false) : setLoading(true);
    try {
      const res: any = await apiService.getProducts(productId, page, per_page);
      const { products } = res.data;
      products.length < per_page && setEndOfScrollData(true);
      setProductTitle(res.data.name);
      setProducts((prev: any) => [...prev, ...products]); // Append new products
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (scrolled) {
      const loader = false;
      // Fetch categories only if scrolled
      fetchProducts(loader); // Will use the updated 'page' from state
    }
  }, [page]);

  const fetchMoreData = async () => {
    setScrolled(true);
    loadMoreProducts(); // Increment page, and useEffect will handle fetching the data
  };

  const loadMoreProducts = () => {
    setPage((prevPage) => prevPage + 1); // Increment the page
  };

  return (
    <div className="p-1 bg-white rounded-md">
      {/* Loader covering the entire section */}
      {loading ? (
        <div className="flex justify-center items-center pt-64" data-testid="clip-loader">
          {" "}
          {/* Center loader vertically */}
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
        <>
          {/* Product Title and Add to Cart Button */}

          <div className="flex flex-col lg:flex-row justify-between items-center mb-4 space-y-4 lg:space-y-0">
            <h2 className="text-xl lg:text-2xl pl-1 font-bold text-center lg:text-left">
              {productTitle}
            </h2>
            <button className="teal-button text-white px-4 py-2 rounded-md flex items-center justify-center w-full lg:w-auto">
              <span className="mr-2">+</span> Add to cart
            </button>
          </div>

          {/* Breadcrumbs */}
           {/* Conditionally Render Breadcrumb */}
          {/* <div className="mt-2 pl-1">
           {breadcrumb.length > 1 && <Breadcrumb />}
          </div> */}
          <div className="p-1">
            <hr />
          </div>
          {/* Item Detail Card */}
          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={!endOfScrollData}
            loader={
              <div className="flex justify-center items-center h-64 my-4">
                <ClipLoader color="#0000ff" loading={true} size={30} />
              </div>
            }
            height={800}
            scrollThreshold={0.9}
            endMessage={
              <div className="flex justify-center items-center my-4 h-64">
                {" "}
                {/* Set a height for vertical centering */}
                <p className="text-gray-600 font-medium text-center">
                {products.length > 0 ? "Yay! You have seen it all." : "No data available"}
                </p>
              </div>
            }
          >
            {products.map((product, index) => (
              <div className="border rounded-md lg:pr-4 md:pr-4 sm:pr-2 flex flex-col lg:flex-row mt-4">
                {/* Item Number */}
                <div className="bg-blue-100 text-blue-600 font-bold p-4 rounded-md flex items-center justify-center lg:justify-start lg:w-12">
                  {index + 1}
                </div>

                {/* Item Info */}
                <div className="flex w-full items-center p-4 justify-between flex-col lg:flex-row">
                  <div className="flex w-full lg:w-1/4 flex-col">
                    <p className="font-semibold text-center lg:text-left">
                      Cat no.: {product.catalogue_no}
                    </p>
                    <p className="text-center lg:text-left">
                      Metal: {product.type}
                    </p>
                  </div>

                  {/* Dropdown */}
                  <div className="w-full lg:w-1/2 border rounded-md h-full flex items-center bg-gray-200 p-3 mt-4 lg:mt-0">
                    <p className="text-center lg:text-left">{product.name}</p>
                  </div>

                  {/* Vertical Line (hidden on small screens) */}
                  <div className="hidden lg:block h-3/4 border-l mx-4"></div>

                  {/* Quantity Selector */}
                  <div className="flex items-center justify-around border rounded-md p-2 bg-gray-200 w-full lg:w-auto mt-4 lg:mt-0">
                    <button className="p-2 text-gray-600 hover:text-gray-800">
                      <FaMinus />
                    </button>
                    <input
                      type="text"
                      className="w-1/2 text-center border-0 outline-none bg-gray-200"
                      placeholder="Enter Qty."
                    />
                    <button className="p-2 text-gray-600 hover:text-gray-800">
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default Product;
