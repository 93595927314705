import axiosService from "./axiosService";

// Define the API service
const apiService = {
  // Method to register a new dealer
  signup: async (formData: any) => {
    try {
      const response = await axiosService.post(`auth/register`, formData);
      return response.data; // Assuming you want to return the response data
    } catch (error) {
      throw error;
    }
  },

  // Method to fetch countries
  getCountries: async (): Promise<any[]> => {
    try {
      const response = await axiosService.get("/countries");
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  // Login function
  login: async (credentials: { email: string; password: string }) => {
    try {
      const response = await axiosService.post("auth/login", credentials);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  forgotPassword: async (data: { email: string }) => {
    try {
      const response = await axiosService.post("auth/forgot-password", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  resetPassword: async (
    token: string,
    data: { password: string; confirm_password: string }
  ) => {
    try {
      const response = await axiosService.post(
        `auth/reset-password?token=${token}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Method to fetch user approval status
  getApprovalStatus: async (): Promise<any[]> => {
    try {
      const response = await axiosService.get("/company/approval-status");
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  // Method to fetch Root categories
  getRootCategories: async (
    page: number = 1,
    per_page: number = 10
  ): Promise<any[]> => {
    try {
      const response = await axiosService.get("/category/list", {
        page,
        per_page, // Send page and per_page as query parameters
      });
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  getUserAddress: async (): Promise<any[]> => {
    try {
      const response = await axiosService.get("/address?page=1&pagesize=10000");
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  // Method to fetch Sub categories
  getSubCategories: async (
    category_id: string,
    page: number = 1,
    per_page: number = 10
  ): Promise<any[]> => {
    try {
      const response = await axiosService.get(`/category/list/${category_id}`, {
        page,
        per_page, // Send page and per_page as query parameters
      });
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  addUserAddress: async (data: any): Promise<any> => {
    try {
      const response = await axiosService.post("/address", data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  // Method to fetch products
  getProducts: async (
    category_id: any,
    page: number = 1,
    per_page: number = 10
  ): Promise<any[]> => {
    try {
      const response = await axiosService.get(`/product/list/${category_id}`, {
        page,
        per_page, // Send page and per_page as query parameters
      });
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  // Method to delete user address
  deleteUserAddress: async (user_id: any): Promise<any> => {
    try {
      const response = await axiosService.delete(`/address/${user_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Method to delete user address
  editUserAddress: async (address_id: any, data: any): Promise<any> => {
    try {
      const response = await axiosService.put(`/address/${address_id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Method to fetch searched products
  getSearchedProducts: async (
    productName: string,
    page: number = 1,
    per_page: number = 10
  ): Promise<any[]> => {
    try {
      const response = await axiosService.get(`/product/search/list`, {
        search: productName, // Pass productName as search query parameter
        page, // Pass page number as query parameter
        pagesize: per_page, // Pass page size as query parameter
      });
      return response.data as any[]; // Type assertion here
    } catch (error) {
      throw error;
    }
  },

  // User Crud
  getUserProfile: async (): Promise<any> => {
    try {
      const response = await axiosService.get(`/user`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateUserProfile: async (data: any): Promise<any> => {
    try {
      const response = await axiosService.put(`/user/update-profile`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
