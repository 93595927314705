import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TEXT_CONSTANTS } from "../../../shared/constants/globalConstants";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidationSchema } from "../../../shared/constants/validationSchemas";
import apiService from "../../../services/apiService";
import helperService from "../../../services/helperService";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import  RegisteredSuccess  from "../../../shared/components/registered-success/RegisteredSuccess";

export default function Register() {
  //# region variables
  const [steps, setSteps] = useState([
    { label: "Personal Details", status: "in-progress" },
    { label: "Company Details", status: "not-completed" },
    { label: "Licence & Business details", status: "not-completed" },
  ]);

  // Track the current step index
  const [currentStep, setCurrentStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registeredSuccess, setRegisteredSuccess] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user: {},
    company: {},
  }); // Initialize formData
 
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(registerValidationSchema),
    mode: "onBlur",
  });

  //methoud that handles signup api call and signs up user
   const handleSignUp = async (data: any) => {
    setLoading(true); // Start loader
    data = helperService.removeEmptyKeys(data);
    try {
      const response: any = await apiService.signup(data);
      const successMessage = helperService.extractSuccessMessage(response);
      toast.success(successMessage);
      setRegisteredSuccess(true);
    } catch (error: any) {
      console.error("Signup failed:", error);
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Stop loader
    }
  };
  //# region functions
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data: any[] = await apiService.getCountries();
        setCountries(data); // TypeScript should recognize the type now
      } catch (error) {
        const errorMessage = helperService.extractErrorMessage(error);
        toast.error(errorMessage);
      }
    };
    fetchCountries();
  }, []);

  //Registraton form is 3 step form and this function handles every step
  const handleNextStep = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    let isStepValid = false;

    // Switch based on the current step
    switch (currentStep) {
      case 0:
        isStepValid = await validateStep0();
        break;
      case 1:
        isStepValid = await validateStep1();
        break;
      case 2:
        isStepValid = await validateStep2();
        break;
      default:
        break;
    }

    if (isStepValid) {
      updateStepper();
    }
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep0 = async () => {
    const isValid = await trigger([
      "first_name",
      "last_name",
      "email",
      "mobile_number",
      "aadhar_number",
    ]);
    if (isValid) {
      updateFormDataStep0();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep0 = () => {
    setFormData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        first_name: watch("first_name"),
        last_name: watch("last_name"),
        email: watch("email"),
        mobile_number: watch("mobile_number"),
        aadhar_number: watch("aadhar_number"),
        user_type: "dealer", // Add static value as per your structure
      },
    }));
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep1 = async () => {
    const isValid = await trigger([
      "company_name",
      "address",
      "city",
      "pincode",
      "state",
      "country",
    ]);
    if (isValid) {
      updateFormDataStep1();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep1 = () => {
    setFormData((prev) => ({
      ...prev,
      company: {
        ...prev.company,
        company_name: watch("company_name"),
        address: watch("address"),
        city: watch("city"),
        pincode: watch("pincode"),
        state: watch("state"),
        country: watch("country"),
      },
    }));
  };

  //validate steps functions are steps to validate a specific step of form before letting user move to next step
  const validateStep2 = async () => {
    const isValid = await trigger([
      "gst_number",
      "drug_licence_number",
      "phone_number",
      "business_type",
      "password",
      "confirm_password",
      "termsAccepted",
    ]);
    if (isValid) {
      updateFormDataStep2();
    }
    return isValid;
  };

  //update form data are functions that maintains the value collected form each step of form and store the all collected value in one single variable
  const updateFormDataStep2 = () => {
    const newFormData = {
      ...formData,
      company: {
        ...formData.company,
        gst_number: watch("gst_number"),
        drug_licence_number: watch("drug_licence_number"),
        phone_number: watch("phone_number"),
        business_type: watch("business_type"),
      },
      user: {
        ...formData.user,
        password: watch("password"),
      },
    };

    // Update state
    setFormData(newFormData);
    handleSignUp(newFormData);
  };

  const updateStepper = () => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      if (currentStep < updatedSteps.length - 1) {
        updatedSteps[currentStep].status = "completed"; // Complete the current step
        updatedSteps[currentStep + 1].status = "in-progress"; // Set next step to in-progress
        setCurrentStep(currentStep + 1); // Move to the next step
      }
      return updatedSteps;
    });
  };

  const renderCurrentForm = () => {
    return (
      <div className="w-full">
        <div className="m-8">
          <div className="w-full max-w-md">
            <h2 className="text-l mt-10 font-bold text-blue-500 mb-2 align-left">
              {TEXT_CONSTANTS.REGISTER}
            </h2>
            <h3 className="text-xl font-semibold mb-10 align-left">
              {currentStep === 0
                ? TEXT_CONSTANTS.PERSONAL_DETAILS
                : currentStep === 1
                  ? TEXT_CONSTANTS.COMPANY_DETAILS
                  : TEXT_CONSTANTS.LICENSE_BUSINESS_DETAILS}
            </h3>
          </div>
          <div className="w-full">
            <form className="bg-white rounded-lg w-full">
              {currentStep === 0 && (
                <>
                  <div className="flex flex-col space-y-4 mb-4">
                    <div className="mb-4 flex flex-col sm:flex-row sm:space-x-4">
                      <div className="w-full mb-4 sm:mb-0">
                        <label className="block text-l font-small mb-2">
                          {TEXT_CONSTANTS.FIRST_NAME}
                        </label>
                        <input
                          {...register("first_name")}
                          type="text"
                          placeholder={TEXT_CONSTANTS.FIRST_NAME_PLACEHOLDER}
                          className="border rounded-lg p-3 w-full focus:outline-none focus:ring focus:ring-blue-500"
                          onBlur={() => trigger("first_name")}
                        />
                        <p className="text-red-500">
                          {errors?.first_name?.message}
                        </p>
                      </div>
                      <div className="w-full">
                        <label className="block text-l font-small mb-2">
                          {TEXT_CONSTANTS.LAST_NAME}
                        </label>
                        <input
                          {...register("last_name")}
                          type="text"
                          placeholder={TEXT_CONSTANTS.LAST_NAME_PLACEHOLDER}
                          className="border rounded-lg p-3 w-full focus:outline-none focus:ring focus:ring-blue-500"
                          onBlur={() => trigger("last_name")}
                        />
                        <p className="text-red-500">
                          {errors?.last_name?.message}
                        </p>
                      </div>
                    </div>

                    <div className="w-full mb-4">
                      <label className="block text-l font-small mb-2">
                        {TEXT_CONSTANTS.EMAIL_ADDRESS}
                      </label>
                      <input
                        {...register("email")}
                        type="email"
                        placeholder={TEXT_CONSTANTS.EMAIL_PLACEHOLDER}
                        className="border rounded-lg p-3 w-full focus:outline-none focus:ring focus:ring-blue-500"
                        onBlur={() => trigger("email")}
                      />
                      <p className="text-red-500">{errors?.email?.message}</p>
                    </div>
                    <div className="w-full mb-4">
                      <label className="block text-l font-small mb-2">
                        {TEXT_CONSTANTS.MOBILE_NUMBER}
                      </label>
                      <input
                        {...register("mobile_number")}
                        type="text"
                        placeholder={TEXT_CONSTANTS.PHONE_NUMBER_PLACEHOLDER}
                        maxLength={10}
                        className="border rounded-lg p-3 w-full focus:outline-none focus:ring focus:ring-blue-500"
                      />
                      <p className="text-red-500">
                        {errors?.mobile_number?.message}
                      </p>
                    </div>
                    <div className="w-full mb-4">
                      <label className="block text-l font-small mb-2">
                        {TEXT_CONSTANTS.AADHAR_NUMBER}
                      </label>
                      <input
                        {...register("aadhar_number")}
                        type="text"
                        placeholder={TEXT_CONSTANTS.AADHAR_PLACEHOLDER}
                        maxLength={12}
                        className="border rounded-lg p-3 w-full focus:outline-none focus:ring focus:ring-blue-500"
                      />
                      <p className="text-red-500">
                        {errors?.aadhar_number?.message}
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-white rounded p-3 w-full font-semibold button-color"
                    onClick={handleNextStep}
                  >
                    {TEXT_CONSTANTS.NEXT_BUTTON}
                  </button>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <div className="flex flex-col space-y-4 mb-4">
                    <div className="mb-4">
                      <label className="block text-gray-700 font-small mb-2">
                        {TEXT_CONSTANTS.COMPANY_NAME}
                      </label>
                      <input
                        {...register("company_name")}
                        type="text"
                        name="company_name"
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                        placeholder={TEXT_CONSTANTS.COMPANY_NAME_PLACEHOLDER}
                        onBlur={() => trigger("company_name")}
                      />
                      <p className="text-red-500">
                        {errors?.company_name?.message}
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 font-small mb-2">
                        {TEXT_CONSTANTS.ADDRESS}
                      </label>
                      <input
                        {...register("address")}
                        type="text"
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                        placeholder={TEXT_CONSTANTS.ADDRESS_PLACEHOLDER}
                        onBlur={() => trigger("address")}
                      />
                      <p className="text-red-500">{errors?.address?.message}</p>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.CITY}
                        </label>
                        <input
                          {...register("city")}
                          type="text"
                          name="city"
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={TEXT_CONSTANTS.CITY_PLACEHOLDER}
                          onBlur={() => trigger("city")}
                        />
                        <p className="text-red-500">{errors?.city?.message}</p>
                      </div>
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.PINCODE}
                        </label>
                        <input
                          {...register("pincode")}
                          type="text"
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={TEXT_CONSTANTS.PINCODE_PLACEHOLDER}
                          onBlur={() => trigger("pincode")}
                        />
                        <p className="text-red-500">
                          {errors?.pincode?.message}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.STATE}
                        </label>
                        <input
                          {...register("state")}
                          type="text"
                          name="state"
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={TEXT_CONSTANTS.STATE_PLACEHOLDER}
                          onBlur={() => trigger("state")}
                        />
                        <p className="text-red-500">{errors?.state?.message}</p>
                      </div>
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.COUNTRY}
                        </label>
                        <select
                          {...register("country")}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          onBlur={() => trigger("country")}
                        >
                          <option value="USA">
                            {TEXT_CONSTANTS.COUNTRY_PLACEHOLDER}
                          </option>
                          {countries?.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <button
                      className="text-white rounded p-3 w-full font-semibold button-color"
                      onClick={handleNextStep}
                    >
                      {TEXT_CONSTANTS.NEXT_BUTTON}
                    </button>
                  </div>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <div className="flex flex-col space-y-4 mb-4">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.GST_NUMBER}
                        </label>
                        <input
                          {...register("gst_number")}
                          type="text"
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={TEXT_CONSTANTS.GST_NUMBER_PLACEHOLDER}
                        />
                      </div>

                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.DRUG_LICENSE_NUMBER}
                        </label>
                        <input
                          {...register("drug_licence_number")}
                          type="text"
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={
                            TEXT_CONSTANTS.DRUG_LICENSE_NUMBER_PLACEHOLDER
                          }
                          onBlur={() => trigger("drug_licence_number")}
                        />
                        <p className="text-red-500">
                          {errors?.drug_licence_number?.message}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.PHONE_NUMBER}
                        </label>
                        <input
                          {...register("phone_number")}
                          type="text"
                          maxLength={10}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder={TEXT_CONSTANTS.PHONE_NUMBER_PLACEHOLDER}
                          onBlur={() => trigger("phone_number")}
                        />
                        <p className="text-red-500">
                          {errors?.phone_number?.message}
                        </p>
                      </div>

                      <div className="w-full">
                        <label className="block text-gray-700 font-small mb-2">
                          {TEXT_CONSTANTS.BUSINESS_TYPE}
                        </label>
                        <select
                          {...register("business_type")}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                        >
                          <option value="domestic">Domestic</option>
                          <option value="international">International</option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 font-small mb-2">
                        {TEXT_CONSTANTS.PASSWORD}
                      </label>
                      <div className="relative w-full">
                        <input
                          {...register("password")}
                          type={showPassword ? "text" : "password"}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder="Enter your password"
                          onBlur={() => trigger("password")}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <FaEyeSlash className="h-5 w-5 text-gray-500" />
                          ) : (
                            <FaEye className="h-5 w-5 text-gray-500" />
                          )}
                        </button>
                      </div>
                      <p className="text-red-500">
                        {errors?.password?.message}
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 font-small mb-2">
                        {TEXT_CONSTANTS.CONFIRM_PASSWORD}
                      </label>
                      <div className="relative w-full">
                        <input
                          {...register("confirm_password")}
                          type={showConfirmPassword ? "text" : "password"}
                          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                          placeholder="Confirm your password"
                          onBlur={() => trigger("confirm_password")}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <FaEyeSlash className="h-5 w-5 text-gray-500" />
                          ) : (
                            <FaEye className="h-5 w-5 text-gray-500" />
                          )}
                        </button>
                      </div>
                      <p className="text-red-500">
                        {errors?.confirm_password?.message}
                      </p>
                    </div>

                    <div className="mb-4 flex items-center">
                      <input
                        {...register("termsAccepted")}
                        type="checkbox"
                        className="mr-2 checkbox"
                      />
                      <label className="text-gray-700">
                        {TEXT_CONSTANTS.TERMS_AND_POLICY}
                      </label>
                    </div>
                    <p className="text-red-500 mb-4">
                      {errors?.termsAccepted?.message}
                    </p>
                    <button
                      onClick={handleNextStep} // Make sure to pass the necessary data to this function
                      className="button-color text-white rounded p-3 w-full font-semibold"
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? (
                        <ClipLoader color="#ffffff" size={24} />
                      ) : (
                        TEXT_CONSTANTS.SIGN_UP_BUTTON
                      )}
                    </button>
                  </div>
                </>
              )}
            </form>
            <div className="flex flex-col justify-center items-center">
              <p className="mt-6 text-sm font-bold">
                {TEXT_CONSTANTS.ALREADY_HAVE_ACCOUNT}{" "}
                <a href="/sign-in" className="text-blue">
                  {TEXT_CONSTANTS.SIGN_IN}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
  { registeredSuccess ? ( <RegisteredSuccess/> ):(
      <div className="flex flex-col lg:flex-row min-h-screen">
     
        {/* Left Section */}
        <div className="w-full lg:w-1/2 p-12 flex flex-col justify-between items-start text-white gradient">
          <div>
            <h1 className="text-3xl font-bold mt-8 mb-16">
              {TEXT_CONSTANTS.PRODUCT_TITLE}
            </h1>
            <p className="text-2xl font-semibold mb-6 mt-28 pt-5 side-heading">
              {TEXT_CONSTANTS.SIDE_HEADING}
              <span className="italic heading">one place</span>
            </p>
            <div className="space-y-4 mt-12 pt-12">
              <div className="flex flex-col space-y-8 relative">
                {steps.map((step, index) => (
                  <div key={index} className="flex items-center">
                    {/* Line connecting steps */}

                    {/* Step circles */}
                    <div className="relative z-10">
                      {step.status === "completed" ? (
                        <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                          <svg
                            className="w-5 h-5 "
                            fill="none"
                            stroke="#6575EA" 
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className={`w-8 h-8 rounded-full flex items-center justify-center ${
                            step.status === "in-progress"
                              ? "bg-white border-2 border-gray-300"
                              : "border-2 bg-transparent"
                          }`}
                        >
                          {step.status === "in-progress" && (
                            <div className="w-2 h-2 stepper-color rounded-full"></div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Step label */}
                    <div className="ml-4 text-white text-2xl font-semibold">
                      {step.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="align-center">
            <p className="text-md">{TEXT_CONSTANTS.COMPLETE_STEPS}</p>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/2 bg-white flex flex-col justify-center items-center p-12">
          {renderCurrentForm()}
        </div>
      </div>
         )}
    </>
  );
}
