import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import apiService from "../../../services/apiService";
import helperService from "../../../services/helperService";
import { TEXT_CONSTANTS } from "../../../shared/constants/globalConstants";
import { loginValidationSchema } from "../../../shared/constants/validationSchemas"; // Adjust import according to your schema

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    mode: "onBlur",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await apiService.login(data);
      if (response.status) {
        localStorage.setItem("refreshToken", response.data.refreshToken);
        await localStorage.setItem("accessToken", response.data.accessToken);
        toast.success("Login Success");
        fetchApprovalStatus();
      } else toast.error(`${response.message}`);
    } catch (error) {
      console.log(error);
      toast.error("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovalStatus = async () => {
    try {
      const res: any = await apiService.getApprovalStatus();
      await localStorage.setItem("isUserApproved", res.data.approval_status);
      if (res?.data?.approval_status) {
        navigate("/");
      } else {
        navigate("/approval-status");
      }
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      <div className="gradient flex w-full flex-col items-start justify-center p-12 text-white lg:w-1/2">
        <h3 className="absolute start-12 top-10 mt-10 text-3xl font-bold">
          {" "}
          {TEXT_CONSTANTS.PRODUCT_TITLE}
        </h3>
        <h1 className="mb-6 text-4xl font-bold">
          Manage all your orders and products at{" "}
          <span className="heading italic">one place</span>
        </h1>
        <p className="mb-6 text-lg">
          Everything you want from ERP like managing products and tracking
          orders at one place
        </p>
      </div>

      <div className="flex w-full flex-col items-center justify-center p-12 lg:w-1/2">
        <div className="w-full max-w-md">
          <h2 className="mb-6 text-2xl font-bold">Sign In</h2>
          <form
            className="rounded-lg bg-white"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-4">
              <label className="mb-2 block text-gray-700">Email</label>
              <input
                {...register("email")}
                type="email"
                className="w-full rounded-lg border p-3 focus:outline-none focus:ring focus:ring-blue-500"
                onBlur={() => trigger("email")}
                placeholder="Enter your email"
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-gray-700">Password</label>
              <div className="relative">
                <input
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  className="w-full rounded-lg border p-3 focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="Enter your password"
                  onBlur={() => trigger("password")}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? (
                    <FaEyeSlash className="h-5 w-5 text-gray-500" />
                  ) : (
                    <FaEye className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
              <p className="text-red-500">{errors.password?.message}</p>
              <div className="pt-2">
                <a
                  href="/forgot-password"
                  className="flex justify-end pt-2 text-blue-500"
                >
                  Forgot password ?
                </a>
              </div>
            </div>

            <button
              type="submit"
              className="w-full rounded bg-blue-500 p-3 font-semibold text-white hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? <ClipLoader color="#ffffff" size={24} /> : "Sign In"}
            </button>
          </form>

          <div className="mt-4 flex flex-col items-center justify-center">
            <p className="text-sm font-bold">
              Don't have an account?{" "}
              <a href="/register" className="text-blue-500">
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
