import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import apiService from "../../../services/apiService";
import { emailValidationSchema } from "../../../shared/constants/validationSchemas";
import { TEXT_CONSTANTS } from "../../../shared/constants/globalConstants";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
    mode: "onBlur",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await apiService.forgotPassword(data);
      if (response.status) {
        toast.success("Email sent successfully. Please check your inbox.");
        navigate("/sign-in");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to send email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col lg:flex-row">
      <div className="gradient flex w-full flex-col items-start justify-center p-12 text-white lg:w-1/2">
        <h3 className="absolute start-12 top-10 mt-12 text-3xl font-bold">
          {TEXT_CONSTANTS.PRODUCT_TITLE}
        </h3>
        <h1 className="mb-6 text-4xl font-bold">
          Manage all your orders and products at {""}
          <span className="heading italic">one place</span>
        </h1>
        <p className="mb-6 text-lg">
          Everything you want from ERP like managing products and tracking
          orders at one place
        </p>
      </div>
      <div className="flex w-full flex-col items-center justify-center p-12 lg:w-1/2">
        <div className="w-full max-w-md">
          <h2 className="mb-6 text-2xl font-bold">Forgot Password</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
            <div className="mb-4">
              <label className="mb-2 block">Email</label>
              <input
                {...register("email")}
                type="email"
                className="w-full rounded-lg border p-3 focus:outline-none focus:ring focus:ring-blue-500"
                onBlur={() => trigger("email")}
                placeholder="Enter your email"
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </div>
            <button
              type="submit"
              className="w-full rounded bg-blue-500 p-3 font-semibold text-white hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#ffffff" size={24} />
              ) : (
                "Send Reset Link"
              )}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center justify-center">
            <p className="text-sm font-bold">
              {TEXT_CONSTANTS.ALREADY_HAVE_ACCOUNT}{" "}
              <a href="/sign-in" className="text-blue-500">
                {TEXT_CONSTANTS.SIGN_IN}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
