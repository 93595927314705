import axios, { AxiosInstance, AxiosResponse } from "axios";

const baseURL = "https://dealer-api.sadhrasya.in";

interface AxiosService {
  get<T>(url: string, params?: any): Promise<AxiosResponse<T>>;
  post<T>(url: string, data?: any): Promise<AxiosResponse<T>>;
  put<T>(url: string, data?: any): Promise<AxiosResponse<T>>;
  delete<T>(url: string): Promise<AxiosResponse<T>>;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async (config: any) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Failed to retrieve access token:", error);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = "/sign-in";
    }
    return Promise.reject(error);
  }
);

const axiosService: AxiosService = {
  get: async <T>(url: string, params: any = {}): Promise<AxiosResponse<T>> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  post: async <T>(url: string, data: any = {}): Promise<AxiosResponse<T>> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.post(url, data);
      return response;
    } catch (error: any) {
      throw error;
    }
  },

  put: async <T>(url: string, data: any = {}): Promise<AxiosResponse<T>> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  delete: async <T>(url: string): Promise<AxiosResponse<T>> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default axiosService;
