// src/components/Breadcrumb.tsx
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateBreadcrumb, resetBreadcrumb } from "../../../redux/slices/breadcrumSlice";

const Breadcrumb = () => {
  const dispatch = useDispatch();

  const breadcrumb = useSelector(
    (state: RootState) => state.breadcrumb.breadcrumb
  );

  const handleBreadcrumbClick = (category_id: string | null) => {
    if (category_id === 'root' || category_id === null) {
      dispatch(resetBreadcrumb()); 
    } else {
      const index = breadcrumb.findIndex(item => item.category_id === category_id);
      if (index !== -1) {
        // Update breadcrumb to keep only up to the clicked category
        dispatch(updateBreadcrumb(index));
    }
    }
  };

  return (
    <nav className="text-sm mb-2 flex">
    {breadcrumb.map((item, index) => (
      <span key={item.category_id} className="flex items-center">
        {index === breadcrumb.length - 1 ? (
          <p className="text-black">{item.name}</p> // Last element with no click event
        ) : (
          <p
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={() => handleBreadcrumbClick(item.category_id)}
          >
            {item.name}
          </p>
        )}
        {index < breadcrumb.length - 1 && <span className="mx-2">/</span>} {/* Add spacing around the separator */}
      </span>
    ))}
  </nav>
   
  );
};

export default Breadcrumb;
